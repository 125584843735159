<script>
import Layout from "@/router/layouts/main";
import { api } from "@/state/services";
import { VclList } from "vue-content-loading";
import { required } from "vuelidate/lib/validators";
import { VMoney } from "v-money";

export default {
  locales: {
    en: {},
    pt: {
      "We apologize, we are unable to retrieve information at this time. Please try again later.":
        "Pedimos desculpas, não podemos recuperar informações no momento. Por favor, tente novamente mais tarde.",
      "No records found.": "Nenhum registro encontrado.",
    },
    es: {
      "We apologize, we are unable to retrieve information at this time. Please try again later.":
        "Nos disculpamos, no podemos recuperar información en este momento. Por favor, inténtelo de nuevo más tarde.",
      "No records found.": "No se encontraron registros.",
    },
  },
  components: { Layout, VclList },
  data() {
    return {
      loading: false,
      success: false,
      value: "",

      table: {
        body: null,
        loading: true,
        errored: false,
        empty: false,
      },
      deposits: null,

      money: {
        decimal: ".",
        thousands: ",",
        prefix: "US$ ",
        suffix: "",
        precision: 2,
      },
      alert: {
        type: "",
        message: "",
        params: [],
      },
    };
  },
  directives: { money: VMoney },
  validations: {
    value: { required },
  },
  methods: {
    getDeposits() {
      this.table.body = null;

      this.table.loading = true;
      this.table.errored = false;
      this.table.empty = false;

      api
        .get("wallet/deposits")
        .then((response) => {
          if (response.data.status == "success") {
            this.table.body = response.data.list;
          } else {
            this.table.body = null;
          }
        })
        .catch((error) => {
          this.table.errored = error;
        })
        .finally(() => {
          this.table.loading = false;
          if (this.table.body == "" || this.table.body == null) {
            this.table.empty = true;
          }
        });
    },
    createDeposit() {
      this.loading = true;
      this.$v.$touch();

      if (this.value) {
        api
          .post("wallet/deposits", {
            value: this.value,
          })
          .catch((error) => {
            if (error) {
              this.loading = false;
            }
          })
          .then((response) => {
            if (response.data.status == "success") {
              this.value = "US$ 0.00";
              this.$v.$reset();

              this.$router.push("/store/order/" + response.data.order.id);
            } else {
              this.alert.type = "alert-danger";
              this.alert.message = response.data.message;
              if (response.data.params) {
                this.alert.params = response.data.params;
              } else {
                this.alert.params = [];
              }

              this.$v.$reset();
            }

            this.loading = false;
          });
      }
    },
  },
  mounted() {
    this.getDeposits();
  },
};
</script>

<template>
  <Layout>
    <div class="row">
      <div class="col-12">
        <div class="page-title-box d-flex justify-content-between">
          <h4 class="mb-0 font-size-18">Deposit</h4>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-xl-7">
        <div class="card">
          <div class="card-body p-4">
            <b-form class="p-3" @submit.prevent="createDeposit">
              <div v-if="alert.message" :class="'alert ' + alert.type">
                {{ t(alert.message) }}
              </div>
              <b-form-group
                id="value"
                :label="t('Deposit Value')"
                label-for="value"
              >
                <b-form-input
                  id="value"
                  v-model="value"
                  v-money="money"
                  type="text"
                  inputmode="decimal"
                  :class="{ 'is-invalid': $v.value.$error }"
                ></b-form-input>
                <div v-if="$v.value.$error" class="invalid-feedback">
                  <span v-if="!$v.value.required">{{
                    t("Amount is required.")
                  }}</span>
                </div>
              </b-form-group>
              <div class="mt-4">
                <b-button
                  :disabled="loading == true || this.value === 'US$ 0.00'"
                  type="submit"
                  variant="default"
                >
                  {{ t("Deposit") }}
                  <b-spinner
                    v-if="loading"
                    small
                    class="ml-2 align-middle"
                    variant="white"
                    role="status"
                  ></b-spinner>
                </b-button>
              </div>
            </b-form>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <div v-if="table.errored">
              {{
                t(
                  "We apologize, we are unable to retrieve information at this time. Please try again later."
                )
              }}
            </div>
            <div v-else-if="table.empty">{{ t("No records found.") }}</div>
            <div v-else class="table-responsive">
              <vcl-list v-if="table.loading" class="col-lg-6"></vcl-list>
              <table v-else class="table table-centered table-nowrap">
                <thead class="thead-light">
                  <tr>
                    <th>#</th>
                    <th>Date</th>
                    <th>Value</th>
                    <th>Status</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(td, index) in table.body" :key="index">
                    <td>{{ td.id }}</td>
                    <td>{{ td.date }}</td>
                    <td>{{ td.value | currency }}</td>
                    <td>
                      <span
                        v-if="td.status === 'pending'"
                        class="badge badge-soft-warning font-size-12"
                        >{{ t("Aguardando pagamento") }}</span
                      >
                      <span
                        v-else-if="td.status === 'approved'"
                        class="badge badge-soft-success font-size-12"
                        >{{ t("Aprovado") }}</span
                      >
                      <span
                        v-else-if="td.status === 'canceled'"
                        class="badge badge-soft-danger font-size-12"
                        >{{ t("Cancelado") }}</span
                      >
                    </td>
                    <td class="text-right">
                      <router-link
                        :to="'/store/order/' + td.order.id"
                        class="btn btn-default btn-sm btn-rounded"
                      >
                        Ver
                        <i class="mdi mdi-arrow-right ml-1"></i>
                      </router-link>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>
